<template>
  <div class="scorecard-picker-container">
    <div class="title"><h4>Which scorecard?</h4></div>

    <div
      class="scorecard-container"
      v-for="scorecard in myScorecards"
      :key="scorecard.pool.id"
    >
      <h3 class="event-name">
        {{ scorecard.pool.round.event.name }} {{ roundAndPool(scorecard) }}
      </h3>

      <div class="tour-name" v-if="singleEvent(scorecard)">
        {{ scorecard.pool.round.event.name }}
      </div>

      <StandardSolidButton
        class="to-scorecard"
        title="Your scorecard"
        :fluid="false"
        @click="
          $router.push({ name: 'marker', params: { groupId: scorecard.id } })
        "
      />
    </div>
    <div
      class="scorecard-container"
      v-for="scorecard in myMarshallGroups"
      :key="scorecard.pool.id"
    >
      <div class="header">
        {{ scorecard.pool.round.event.tour.name }}
      </div>

      <div class="body">
        {{ scorecard.pool.round.event.name }}
      </div>

      <div class="footer">
        <router-link
          :to="{ name: 'marker', params: { groupId: scorecard.id } }"
        >
          <button class="gold solid">Marshall scorecard</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";

export default {
  name: "TheScorecardPicker",
  components: {
    StandardSolidButton,
  },
  data() {
    return {
      myScorecards: [],
      myMarshallGroups: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    roundAndPool(scorecard) {
      const poolId = scorecard.pool.id;
      const roundId = scorecard.pool.round.id;
      const rounds = scorecard.pool.round.event.rounds;
      let poolNumber;
      let roundNumber;

      rounds.forEach((round, roundIndex) => {
        if (round.id == roundId) roundNumber = roundIndex + 1;

        round.pools.forEach((pool, poolIndex) => {
          if (pool.id == poolId) poolNumber = poolIndex + 1;
        });
      });

      if (rounds.length == 1) {
        return "";
      }

      return `- R${roundNumber}`;
    },
    singleEvent(scorecard) {
      return scorecard.pool.round.event.tour.events.length > 1;
    },
    loadData() {
      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          {
            me{
              marshalls(status:OPEN){
                id
                pool{
                  round{
                    event{
                      name
                      tour{
                        name
                        events{
                          id
                        }
                      }
                    }
                  }
                }
              }
              groups(
                filter: {
                  operator: OR
                  query: [
                      { operator: AND, groupStatus: OPEN, poolStatus: OPEN },
                      { operator: AND, groupStatus: DONE, poolStatus: OPEN }
                  ]
                }
              ){
                id
                pool{
                  id
                  round{
                    id
                    event{
                      rounds{
                        id
                        pools{
                          id
                        }
                      }
                      name
                      tour{
                        name
                        events{
                          id
                        }
                      }
                    }
                  }
                }
                playerConnections{
          				player{
                    userId
                  }
                }
                marshall{
                  id
          				profile{
                    id
                  }
                }
              }
            }
          }
          `,
        },
      })
        .then((result) => {
          this.myScorecards = result.data.data.me.groups;
          this.myMarshallGroups = result.data.data.me.marshalls;

          if (
            this.myScorecards.length == 0 &&
            this.myMarshallGroups.length == 0
          ) {
            this.$router.push({ name: "home" });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/Typography";
.scorecard-container {
  padding: 0 12px 32px 12px;
  border-bottom: 1px solid $fog;
}
.title {
  height: 48px;
  text-align: center;
  background: $mist;
  border-bottom: 1px solid $fog;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-name {
  margin-top: 24px;
}

.tour-name {
  margin-top: 4px;
  color: $strom;
}

.to-scorecard {
  margin-top: 18px;
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}
@media (min-width: 992px) {
  .scorecard-picker-container {
    max-width: 400px;
    margin: 0 auto;
  }
}
</style>
